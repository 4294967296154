import { SECTIONS } from './sections';

export const FEDOPS_INTERACTIONS = {
  [SECTIONS.ABOUT]: 'add-about-page',
  [SECTIONS.FOLLOWERS]: 'add-followers-page',
  [SECTIONS.NOTIFICATIONS]: 'add-notifications-page',
  [SECTIONS.SETTINGS]: 'add-settings-page',
  [SECTIONS.ALL_MEMBERS]: 'add-allMembers-page',
  [SECTIONS.POSTS]: 'add-posts-page',
  [SECTIONS.COMMENTS]: 'add-comments-page',
  [SECTIONS.LIKES]: 'add-likes-page',
  [SECTIONS.DRAFTS]: 'add-drafts-page',
};
