import { FEDOPS_INTERACTIONS } from '../constants/fedops';
import { ALL_SECTIONS } from '../constants/sections';
import dependentApps from '../constants/dependent-apps';
import memberPages from '../constants/member-pages';
import monitoring from './monitoring';

const withDefaultPageProps = page =>
  Object.assign(
    {
      numbers: {},
      showInLoginMenu: false,
      showInMemberMenu: true,
      social: true,
      socialHome: false,
      urlOverride: null,
      visibleForRoles: [],
    },
    page,
  );

const addApplications = (membersAPI, applications) =>
  membersAPI.addApplications(applications.map(withDefaultPageProps));

const inSequence = installers =>
  installers.reduce((promise, installSection) => promise.then(installSection), Promise.resolve());

const createInstallFunction = installStrategy => async (addPage, sections = ALL_SECTIONS) => {
  const sectionConfig = { ...dependentApps, ...memberPages };
  const installers = sections.map(sectionName => () =>
    addPage(FEDOPS_INTERACTIONS[sectionName], sectionConfig[sectionName]),
  );
  return monitoring.toMonitored('install-blog-sections', installStrategy(installers));
};

const installInSequence = createInstallFunction(inSequence);
const installInParallel = addApplications;

export default {
  installInSequence,
  installInParallel,
};
