import { BASE_API_URL } from '../constants/api';
import { BLOG_COMMON_COMP_ID } from '../constants/components';

export const getSettingsApiUrl = (instance, compId, externalId) =>
  `${BASE_API_URL}/_api/settings?instance=${instance}&compId=${compId}&externalId=${externalId}&viewMode=${
    externalId === 'published' ? 'site' : 'editor'
  }`;

export const fetchSettings = async (instance, compId, externalId) =>
  fetch(getSettingsApiUrl(instance, compId, externalId), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then(res => {
    if (!res.ok) {
      throw Error(res.statusText);
    }
    return res.json();
  });

export const patchSettings = async (instance, compId, externalId, settings) =>
  fetch(getSettingsApiUrl(instance, compId, externalId), {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(settings),
  }).then(res => {
    if (!res.ok) {
      throw Error(res.statusText);
    }
  });

export const getCommonSettings = (instance, externalId) => fetchSettings(instance, BLOG_COMMON_COMP_ID, externalId);

export const setCommonSettings = (instance, externalId, settings) =>
  patchSettings(instance, BLOG_COMMON_COMP_ID, externalId, settings);

export default {
  getCommonSettings,
  setCommonSettings,
};
