import { get } from 'lodash';
import {
  EXPERIMENT_BLOG_PROVISIONING_MODAL,
  EXPERIMENT_PRO_GALLERY_OLD_EDITOR_FOR_NEW,
} from '@wix/communities-blog-experiments';
import {
  LAYOUT_PG_SIDE_BY_SIDE,
  LAYOUT_PG_GRID,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import { AUTOPILOT_USER_UUID } from '../constants/users';
import { blogAppDefId, membersAreaAppDefId } from '../constants/apps';
import { PROVISIONING_MODAL } from '../constants/modal-panels';
import { getPanelOptions } from './settings-panels';
import { ALL_SECTIONS, SECTIONS_WITHOUT_ALL_MEMBERS } from '../constants/sections';
import { TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import dependentApps from '../constants/dependent-apps';
import bi from './bi';
import { getSiteMemberId } from './instance';
import sections from './sections';
import monitoring from './monitoring';
import experiments from './experiments';
import page from './page';
import memberPages from '../constants/member-pages';
import { LAYOUT_PANEL, MAIN_PANEL } from '../constants/settings-panels';
import { openModalPanel } from './modal';
import {
  HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM,
  HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL,
  CATEGORY_LAYOUT_TYPE_STYLE_PARAM,
} from '../constants/style-params';
import { BLOG_WIDGET_ID } from '../constants/widgets';

export const getMembersApi = async ({ sdk, appToken }) =>
  sdk.application.getPublicAPI(appToken, { appDefinitionId: membersAreaAppDefId });

export const installMissingPostPage = async ({ sdk, appToken, instance }) => {
  const userId = await getSiteMemberId(sdk, appToken);
  if (userId === AUTOPILOT_USER_UUID) {
    return;
  }

  let currentPage, isPostPageMissing;
  try {
    [currentPage, isPostPageMissing] = await Promise.all([
      sdk.document.pages.getPrimary(),
      page.isPostPageMissing({ sdk, appToken, instance }),
    ]);
  } catch (e) {
    console.log('Error checking for missing post page:');
    console.log(e);
  }

  if (isPostPageMissing) {
    await sdk.tpa.add.component(appToken, {
      componentType: 'PAGE',
      appDefinitionId: blogAppDefId,
      page: { pageId: TPA_PAGE_ID_POST },
    });

    return sdk.document.pages.navigateTo(appToken, { pageRef: currentPage });
  }

  return Promise.resolve();
};

export const initProvision = ({ sdk }) => monitoring.toMonitored('sdk.document.save', sdk.document.save(), false);

export const initBiService = async ({ sdk, appToken }) => {
  bi.init(await sdk.document.info.getAppInstance(appToken));
};

export const displayProvisioningModal = async ({ sdk, appToken, isADI }) => {
  try {
    bi.provisioningModalDisplayed();
    const { action, value } = await openModalPanel({ sdk, appToken, isADI, modal: PROVISIONING_MODAL });
    bi.provisioningModalClosed(action, value);

    const shouldInstallMembersArea = action === 'next' && value;
    if (shouldInstallMembersArea) {
      return monitoring.toMonitored(
        'install-members-area',
        sdk.application.install(appToken, {
          appDefinitionId: membersAreaAppDefId,
          initiatorAppDefinitionId: blogAppDefId,
        }),
      );
    }
  } catch (error) {
    console.warn(error);
  }
};

export const installBlogSections = async ({ sdk, appToken }) => {
  const isInstalled = await sdk.tpa.isApplicationInstalled(appToken, { appDefinitionId: blogAppDefId });
  if (!isInstalled) {
    return;
  }
  await initProvision({ sdk, appToken });

  const isProvisioningModalEnabled = experiments.isEnabled(EXPERIMENT_BLOG_PROVISIONING_MODAL);
  const membersAPI = await getMembersApi({ sdk, appToken });

  const sectionNames = isProvisioningModalEnabled ? SECTIONS_WITHOUT_ALL_MEMBERS : ALL_SECTIONS;

  const apps = sectionNames.map(name => memberPages[name] || dependentApps[name]);
  return (
    membersAPI &&
    monitoring.toMonitored(
      'install-members-area-apps-in-parallel',
      sections.installInParallel(membersAPI, apps).then(() => setDependantApps({ sdk, appToken })),
    )
  );
};

export async function setDependantApps({ sdk, appToken }) {
  const membersAPI = await getMembersApi({ sdk, appToken });
  const dependencies = Object.keys(dependentApps).map(app => dependentApps[app].appDefinitionId);
  return monitoring.toMonitored(
    'set-dependant-apps',
    membersAPI
      ? membersAPI.setDependantApps({
          appId: blogAppDefId,
          dependencies,
        })
      : Promise.resolve(),
  );
}
const openSettingsPanel = panelType => async ({ sdk, appToken, instance }, componentRef, componentRole) => {
  const options = await getPanelOptions({ sdk, instance, componentRef, panelType, componentRole });

  return sdk.editor.openComponentPanel(appToken, {
    componentRef,
    ...options,
  });
};

const openMainSettings = openSettingsPanel(MAIN_PANEL);
const openLayoutSettings = openSettingsPanel(LAYOUT_PANEL);

export const setDefaultLayout = async ({ sdk, appToken, isADI, isClassicEditor }) => {
  const isPGEnabledForClassicEditor = experiments.isEnabled(EXPERIMENT_PRO_GALLERY_OLD_EDITOR_FOR_NEW);
  if (isADI || (isClassicEditor && !isPGEnabledForClassicEditor)) {
    return;
  }

  const blogAppData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  const blogAppComponents = await sdk.document.tpa.app.getAllCompsByApplicationId(appToken, blogAppData.applicationId);

  const blogComponent = blogAppComponents.find(component => component.widgetId === BLOG_WIDGET_ID);
  if (!blogComponent) {
    return;
  }

  const blogComponentRef = await sdk.document.components.getById(appToken, { id: blogComponent.id });
  const blogComponentStyle = await sdk.components.style.get(appToken, { componentRef: blogComponentRef });

  const homepageLayoutType = get(blogComponentStyle, `style.properties.${HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL}`);
  if (homepageLayoutType) {
    return;
  }

  const layoutType = isClassicEditor ? LAYOUT_PG_SIDE_BY_SIDE : LAYOUT_PG_GRID;

  await sdk.document.tpa.setStyleParams(appToken, {
    compRef: blogComponentRef,
    styleParams: [
      {
        type: 'number',
        key: HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM,
        param: {
          value: layoutType,
        },
      },
      {
        type: 'number',
        key: CATEGORY_LAYOUT_TYPE_STYLE_PARAM,
        param: {
          value: layoutType,
        },
      },
    ],
  });
};

export default {
  getMembersApi,
  initProvision,
  initBiService,
  installBlogSections,
  installMissingPostPage,
  setDependantApps,
  displayProvisioningModal,
  openLayoutSettings,
  openMainSettings,
  setDefaultLayout,
};
