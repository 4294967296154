import biLogger from '@wix/web-bi-logger';
import { instanceValue } from '@wix/wix-instance-parser-js/dist/src';
import { BI_ENDPOINT, BI_SRC } from '../constants/bi';

let logger = null;

const DEFAULTS = {
  src: BI_SRC,
};

const createLogger = ({ instanceId, appDefId, biToken }) =>
  biLogger
    .factory({ endpoint: BI_ENDPOINT })
    .updateDefaults({ ...DEFAULTS, instance_id: instanceId, app_id: appDefId, biToken })
    .logger();

const init = (instance, appDefId) => {
  if (logger) {
    return;
  }
  const instanceId = instanceValue.getValueFromInstance('instanceId', instance);
  const biToken = instanceValue.getValueFromInstance('biToken', instance);
  logger = createLogger({ instanceId, biToken, appDefId });
};

const reset = () => {
  logger = null;
};

const log = params => {
  if (!logger) {
    throw new Error('Service needs to be initialized using bi.init before triggering BI events');
  }

  return logger.log(params);
};

const provisioningModalDisplayed = () =>
  log({
    evid: 329,
  });

const provisioningModalClosed = (action, shouldInstallMembersArea) =>
  log({
    evid: 330,
    action,
    option: shouldInstallMembersArea ? 'with_members_area' : 'without_members_area',
  });

export default {
  init,
  reset,
  log,
  provisioningModalClosed,
  provisioningModalDisplayed,
};
