export const SECTIONS = {
  ABOUT: 'about',
  FOLLOWERS: 'followers',
  NOTIFICATIONS: 'notifications',
  SETTINGS: 'settings',
  ALL_MEMBERS: 'allMembers',
  POSTS: 'posts',
  COMMENTS: 'comments',
  LIKES: 'likes',
  DRAFTS: 'drafts',
};

export const ALL_SECTIONS = [
  SECTIONS.ABOUT,
  SECTIONS.FOLLOWERS,
  SECTIONS.NOTIFICATIONS,
  SECTIONS.SETTINGS,
  SECTIONS.ALL_MEMBERS,
  SECTIONS.POSTS,
  SECTIONS.COMMENTS,
  SECTIONS.LIKES,
  SECTIONS.DRAFTS,
];

export const SECTIONS_WITHOUT_ALL_MEMBERS = ALL_SECTIONS.filter(sectionName => sectionName !== SECTIONS.ALL_MEMBERS);
